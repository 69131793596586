import { ChangeEvent, useContext, useEffect, useState } from "react";
import { City, JobSearchOptionsModel, Profession, Specialty, State } from "../tp-core-types/JobSearchOptionsModel";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import SelectButtonWrapper from "../select-button-wrapper/SelectButtonWrapper";
import { SelectButtonChangeEvent } from "primereact/selectbutton";
import sun_icon from "../assets/sun.svg";
import moon_icon from "../assets/moon.svg";
import sun_moon_icon from "../assets/sun-moon.svg";
import { ShiftCategory } from "../tp-core-types/ShiftCategory";
import LocationMultiSelectWrapper from "../multi-select-wrapper/LocationMultiSelectWrapper";
import SpecialtyMultiSelectWrapper from "../multi-select-wrapper/SpecialtyMultiSelectWrapper";
import { LocationOption, NumberOfShifts } from "../utilities/JobFilterCollaborator";
import { getCitiesFromLocations, getStatesFromLocations } from "../utilities/locationHandler";
import { PloppableContext } from "../utilities/context/PloppableContextProvider";
import checkElement from "../utilities/checkElement";
import "./ExternalJobSearchForm.css";

const defaultFormSubmitURL = process.env.REACT_APP_PORTAL_APP_BASE_URL + "/job-search";

type Props = {
    form_submit_url?: string
    onMultiSelectShow?: () => void;
    onMultiSelectHide?: () => void;
};

export default function ExternalJobSearchForm(props: Props) {
    const formSubmitURL = props.form_submit_url ?? defaultFormSubmitURL;

    const isMobile = window.matchMedia("(max-width: 800px)").matches;
    const isPloppable = useContext(PloppableContext).isPloppable;

    const defaultOnMultiSelectShow = () => {
        if (isMobile) {
            checkElement(".p-multiselect-panel").then((selector) => {
                const panel = selector as HTMLElement;
                panel.style.top = "4rem";
                document.body.style.overflow = "hidden"; //prevent scroll on entire page including this modal
            });
        }
    };

    const defaultOnMultiSelectHide = () => {
        if (isMobile) {
            document.body.style.overflow = "auto"; //restoring scroll behavior for entire page
        }
    };

    const onMultiSelectShow = props.onMultiSelectShow ?? defaultOnMultiSelectShow;
    const onMultiSelectHide = props.onMultiSelectHide ?? defaultOnMultiSelectHide;

    const [searchOptions, setSearchOptions] = useState<{
        professions: Profession[];
        specialties: Specialty[];
        locations: LocationOption[];
    }>({
        professions: [{
            id: "13", name: "Registered Nurse", 
            webOrder: 0,
            isAllied: false,
            specialtyIds: []
        }],
        specialties: [],
        locations: [],
    });

    const [selectedProfession, setSelectedProfession] = useState<string>("13");
    const [selectedSpecialties, setSelectedSpecialties] = useState<Specialty[]>([]);
    const [selectedLocations, setSelectedLocations] = useState<LocationOption[]>([]);
    const [selectedStates, setSelectedStates] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedRadii, setSelectedRadii] = useState<{ cityId: string; radius: string }[]>([]);
    const [selectedPreferredShift, setSelectedPreferredShift] = useState<ShiftCategory | undefined>();
    const [selectedNumberOfShifts, setSelectedNumberOfShifts] = useState<NumberOfShifts | undefined>();

    const getOptions = async () => {
        const jobSearchOptions: JobSearchOptionsModel = await TPCoreAPI.getJobSearchOptions();
        const specialtyOptions = jobSearchOptions.specialties
            .map((option: Specialty) => {
                return {
                    id: option.id.toString(),
                    name: determineSpecialtyOptionName(option),
                    abbreviation: option.abbreviation,
                    longDescription: option.longDescription,
                    altText: option.altText,
                };
            })
            .sort((a, b) => a.name.localeCompare(b.name));
        const stateOptions: State[] = jobSearchOptions.states.map((state: State) => {
            return { ...state, id: state.id };
        });
        const cityOptions: City[] = jobSearchOptions.cities.map((city: City) => {
            return { ...city, id: city.id };
        });
        const locationOptions: LocationOption[] = constructLocationOption(stateOptions, cityOptions);
        locationOptions.unshift( {name: "All Compact States", showAllCompact: true, city: null, id: "-99",radius: null, state: {id: "", abbreviation: "", isCompact: false, name: "" }});

        setSearchOptions({
            professions: jobSearchOptions.disciplines,
            specialties: specialtyOptions,
            locations: locationOptions,
        });
    };

    function isState(location: LocationOption) {
        return location.city == null;
    }

    const constructLocationOption = (stateOptions: State[], cityOptions: City[]) => {
        const stateLocations: LocationOption[] = stateOptions.map((state: State) => {
            return {
                id: state.id,
                name: state.name,
                state: { ...state },
                city: null,
                radius: null,
                showAllCompact: false
            };
        });
        const cityLocations: LocationOption[] = cityOptions.map((city: City) => {
            return {
                id: city.id,
                name: `${city.name}, ${city.stateAbbreviation}`,
                state: stateOptions.filter((s) => s.abbreviation === city.stateAbbreviation)[0],
                city: { ...city },
                radius: "100",
                showAllCompact: false
            };
        });
        return stateLocations.concat(cityLocations);
    };

    useEffect(() => {
        setWidthOfSpecialtiesDropdownBox();
    });

    useEffect(() => {
        getOptions();
    }, []);

    const evaluateSpecialtiesHelperText = () => {
        const selectedSpecialtiesElement = document.getElementsByClassName("p-multiselect-label")[0];
        const selectedSpecialtiesContainerElement = document.getElementsByClassName("p-multiselect-label-container")[0];

        if (selectedSpecialtiesElement && selectedSpecialtiesContainerElement) {
            if (selectedSpecialties.length > 0) {
                selectedSpecialtiesContainerElement.setAttribute("add-more-text", "Add more specialties");
            } else {
                selectedSpecialtiesContainerElement.setAttribute("add-more-text", "");
            }

            if (selectedSpecialties.length > 3) {
                selectedSpecialtiesElement.setAttribute(
                    "more-than-3-selections-text",
                    `+ ${selectedSpecialties.length - 3} more`
                );
            } else {
                selectedSpecialtiesElement.setAttribute("more-than-3-selections-text", "");
            }
        }
    };
    useEffect(() => {
        evaluateSpecialtiesHelperText();
    }, [selectedSpecialties]);

    const evaluateLocationsHelperText = () => {
        const selectedLocationsElement = document.getElementsByClassName("p-multiselect-label")[1];
        const selectedLocationsContainerElement = document.getElementsByClassName("p-multiselect-label-container")[1];
        
        const filteredLocationOptions = selectedLocations.filter((location) => !location.showAllCompact);

        if (selectedLocationsElement && selectedLocationsContainerElement) {
            if (filteredLocationOptions.length > 0) {
                selectedLocationsContainerElement.setAttribute("add-more-text", "Add more locations");
            } else {
                selectedLocationsContainerElement.setAttribute("add-more-text", "");
            }

            if (filteredLocationOptions.length > 3) {
                selectedLocationsElement.setAttribute(
                    "more-than-3-selections-text",
                    `+ ${filteredLocationOptions.length - 3} more`
                );
            } else {
                selectedLocationsElement.setAttribute("more-than-3-selections-text", "");
            }
        }
    };

    const getShouldShowAllCompactStates = (): boolean => {
        const allCompactStates = searchOptions.locations.filter((location) => location.state.isCompact && location.city === null && !location.showAllCompact)
        const allSelectedCompactStates = selectedLocations.filter((location) => location.state.isCompact && location.city === null && !location.showAllCompact)

        return allCompactStates.length === allSelectedCompactStates.length
    }

    useEffect(() => {
        evaluateLocationsHelperText();

        if (selectedLocations.length === 0 && searchOptions.locations.length === 0) {
            return
        }
        const allCompactStateOptionIndex: number = selectedLocations.findIndex((location) => location.showAllCompact)
        const shouldShowAllCompactStatesOptions = getShouldShowAllCompactStates()

        if(shouldShowAllCompactStatesOptions && allCompactStateOptionIndex === -1) {
            const localSelectedLocations = [...selectedLocations];
            localSelectedLocations.push(searchOptions.locations[0])
            setSelectedLocations(localSelectedLocations)
        } else if(!shouldShowAllCompactStatesOptions && allCompactStateOptionIndex !== -1) {
            const localSelectedLocations = [...selectedLocations];
            localSelectedLocations.splice(allCompactStateOptionIndex, 1)
            setSelectedLocations(localSelectedLocations)
        }

    }, [selectedLocations, searchOptions]);

    const handleProfessionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProfession(event.target.value);
    };

    const handleSpecialtiesChange = (event: MultiSelectChangeEvent) => {
        setSelectedSpecialties(event.value);
    };

    const determineSpecialtyOptionName = (option: Specialty): string => {
        return option.name === option.abbreviation || option.abbreviation === ""
            ? option.name
            : option.name + " - " + option.abbreviation;
    };

    const handleLocationChange = (event: MultiSelectChangeEvent) => {
        let items = event.value;

        if (event.value.length > selectedLocations.length) { //an option was selected
            if (items[items.length-1].showAllCompact) {
                const stateLocations = searchOptions.locations.filter((searchOption) => !searchOption.showAllCompact && searchOption.city == null)
                
                stateLocations.forEach((location) => {
                    if (location.state.isCompact && !selectedLocations.some(selectedLocation => selectedLocation.id === location.id)) {
                        items.push(location);
                    }
                });
            } else {
                if (items.length > 1) items.unshift(items.pop()); //move newest selected item to front
            }
        } else { //an option was deselected
            if(selectedLocations.some((location) => location.showAllCompact) && !items.some((location: any) => location.showAllCompact)) {
                items = items.filter((location: any) => location.city !== null || !location.state.isCompact);
            }
        }

        setSelectedLocations(items);
        setSelectedStates(items.filter((selectedLocation: LocationOption) => isState(selectedLocation)));
        setSelectedCities(items.filter((selectedLocation: LocationOption) => !isState(selectedLocation)));
    };

    const handlePreferredShiftChange = (event: SelectButtonChangeEvent) => {
        setSelectedPreferredShift(event.value);
    };

    const handleSelectedNumberOfShiftsChange = (event: SelectButtonChangeEvent) => {
        setSelectedNumberOfShifts(event.value);
    };

    function handleSpecialtiesRemove(idToRemove: string): void {
        setSelectedSpecialties(
            selectedSpecialties.filter((selectedSpecialtie: Specialty) => selectedSpecialtie.id !== idToRemove)
        );
    }

    function handleLocationRemove(idToRemove: string): void {
        setSelectedLocations(
            selectedLocations.filter((selectedLocation: LocationOption) => selectedLocation.id !== idToRemove)
        );
        setSelectedStates(selectedStates.filter((selectedState: State) => selectedState.id !== idToRemove));
        setSelectedCities(selectedCities.filter((selectedCity: City) => selectedCity.id !== idToRemove));
        setSelectedRadii(selectedRadii.filter((selectedRadius) => selectedRadius.cityId !== idToRemove));
    }

    function onCityRadiusDropdownChange(event: ChangeEvent<HTMLSelectElement>) {
        const cityId: string = event.target.id;
        const radius: string = event.target.value;

        const location = selectedLocations.filter((x) => x.id === cityId)[0];
        location.radius = radius;

        const newLocations = selectedLocations.filter((x) => x.id !== cityId);
        newLocations.push(location);

        setSelectedLocations(newLocations);
    }

    function getSelectedStatesInputValue(): string[] {
        const states = getStatesFromLocations(selectedLocations);

        return states.map((location) => {
            return location.state.abbreviation;
        });
    }

    function getSelectedCitiesInputValue(): string[] {
        const cities = getCitiesFromLocations(selectedLocations);

        return cities.map((city) => {
            return `${city.id}_${city.radius}`;
        });
    }

    function setWidthOfSpecialtiesDropdownBox() {
        const panels = document.getElementsByClassName("p-multiselect-panel");
        const inputs = document.getElementsByClassName("p-multiselect-label-container");
        if (panels.length > 0 && inputs.length > 0) {
            const panel = panels[0] as HTMLElement;
            const input = inputs[0] as HTMLElement;

            const inputBoundingRect = input.getBoundingClientRect();

            const newWidth = inputBoundingRect.width;

            panel.style.width = newWidth.toString() + "px";
        }
    }

    return (
        <form
            id="job-search-form"
            method="get"
            action={formSubmitURL}
            name="search-for-jobs-form"
            className="searchForJobsForm"
        >
            <div className="topContainer">
                { isMobile && !isPloppable &&
                    <div className="assignmentText">Let's find the right assignment for you!</div>
                }
                <div className="rowDiv">
                    <div className="columnStyle">
                        <label htmlFor="professionId" className="label">
                            Profession
                        </label>
                        <select
                            id="professionId"
                            data-testid="profession-select"
                            name="professionId"
                            className="professionSelect"
                            onChange={handleProfessionChange}
                            value={selectedProfession}
                        >
                            <optgroup label="Most Common">
                                {searchOptions.professions
                                    .filter((discipline) => discipline.webOrder != null)
                                    .sort((a, b) => (a.webOrder ?? 0) - (b.webOrder ?? 0))
                                    .map((profession, index) => (
                                        <option data-group="1" key={`1. ${profession.id}`} value={profession.id}>
                                            {profession.name}
                                        </option>
                                    ))}
                            </optgroup>
                            <optgroup label="All">
                                {searchOptions.professions
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((profession) => (
                                        <option data-group="2" key={`2. ${profession.id}`} value={profession.id}>
                                            {profession.name}
                                        </option>
                                    ))}
                            </optgroup>
                        </select>
                    </div>
                    <div className="columnStyle">
                        <label className="label">Specialty</label>
                        <SpecialtyMultiSelectWrapper
                            id="specialtyIdMultiSelect"
                            name=""
                            value={selectedSpecialties}
                            onShow={onMultiSelectShow}
                            onHide={onMultiSelectHide}
                            onChange={handleSpecialtiesChange}
                            onRemove={handleSpecialtiesRemove}
                            options={searchOptions.specialties.filter((specialty) => 
                            searchOptions.professions.find((profession) => 
                                profession.id === selectedProfession)?.specialtyIds.includes(parseInt(specialty.id)))}
                            optionLabel="name"
                            filterPlaceholder="Search"
                        />
                        <input
                            id="specialtyId"
                            type="hidden"
                            name="specialtyIds"
                            value={selectedSpecialties.map((specialty: Specialty) => specialty.id)}
                        ></input>
                    </div>
                    <div className="columnStyle">
                        <label className="label">Location</label>
                        <LocationMultiSelectWrapper
                            id="locationIdMultiSelect"
                            name=""
                            value={selectedLocations}
                            onShow={onMultiSelectShow}
                            onHide={onMultiSelectHide}
                            onChange={handleLocationChange}
                            onRemove={handleLocationRemove}
                            options={searchOptions.locations}
                            optionLabel="name"
                            filterPlaceholder="Search"
                            onSelectedOptionDropdownChange={onCityRadiusDropdownChange}
                            radii={selectedRadii}
                        />
                        <input
                            id="stateIds"
                            type="hidden"
                            name="stateIds"
                            value={getSelectedStatesInputValue().map((stateId: string) => stateId)}
                        ></input>
                        <input
                            id="cityIds"
                            type="hidden"
                            name="cityIds"
                            value={getSelectedCitiesInputValue().map((cityId: string) => cityId)}
                        ></input>
                    </div>
                </div>
                <div className="rowDiv">
                    <div className="columnStyle">
                        <label className="label">Preferred Shift</label>
                        <SelectButtonWrapper
                            id="preferred-shift-select-button"
                            className="preferred-shift-select-button"
                            name={""}
                            value={selectedPreferredShift ?? 0}
                            onChange={handlePreferredShiftChange}
                            options={[
                                {
                                    icon: sun_icon,
                                    name1: "Day",
                                    name2: "",
                                    value: ShiftCategory.Days,
                                },
                                {
                                    icon: moon_icon,
                                    name1: "Night",
                                    name2: "",
                                    value: ShiftCategory.Nights,
                                },
                                {
                                    icon: sun_moon_icon,
                                    name1: "Either",
                                    name2: "",
                                    value: ShiftCategory.DaysOrNights,
                                },
                            ]}
                            optionLabel={"name"}
                        />
                        <input
                            data-testid="shift-id"
                            id="shift-id"
                            type="hidden"
                            name="shiftCategoryId"
                            value={selectedPreferredShift}
                        ></input>
                    </div>
                    <div className="columnStyle">
                        <label className="label">Number of Shifts per Week</label>
                        <SelectButtonWrapper
                            id="shifts-per-week-select-button"
                            className="shifts-per-week-select-button"
                            name={""}
                            value={selectedNumberOfShifts ?? 0}
                            onChange={handleSelectedNumberOfShiftsChange}
                            options={[
                                {
                                    icon: "",
                                    name1: "3",
                                    name2: "Shifts",
                                    value: NumberOfShifts.ThreeShifts,
                                },
                                {
                                    icon: "",
                                    name1: "4",
                                    name2: "Shifts",
                                    value: NumberOfShifts.FourShifts,
                                },
                                {
                                    icon: "",
                                    name1: "5",
                                    name2: "Shifts",
                                    value: NumberOfShifts.FiveShifts,
                                },
                                {
                                    icon: "",
                                    name1: "Any #",
                                    name2: "of Shifts",
                                    value: NumberOfShifts.AnyNumberOfShifts,
                                },
                            ]}
                            optionLabel={"name"}
                        />
                        <input
                            data-testid="shifs-per-week-id"
                            id="shifs-per-week-id"
                            type="hidden"
                            name="numberOfShifts"
                            value={selectedNumberOfShifts}
                        ></input>
                    </div>
                    {(!isMobile || !isPloppable) && <div className="columnStyle">
                        <div className="searchButton">
                            <button className="buttonContainer">Search for Jobs</button>
                        </div>
                    </div>
                    }
                </div>
            </div>
            { isMobile && isPloppable && 
                <div className="columnStyle searchButtonDiv">
                    <div className="searchButton">
                        <button className="buttonContainer">Search for Jobs</button>
                    </div>
                </div>
                }
        </form>
    );
}
