import { useContext, useEffect, useState } from "react";
import "./AppStatusTracker.scss";
import FilledDot from "../../assets/app_status_tracker_dot.svg"
import EmptyDot from "../../assets/app_status_tracker_dot_empty.svg"
import FeatureFlagContext from "../../tp-core-types/FeatureFlagsModel";
import { AppStatusTrackerSize } from "./AppStatusTrackerSizes";
import { NavigationSteps } from "../../tp-core-types/NavigationSteps";
import checkmark from "../../assets/checkmark_green.svg";
import greycheckmark from "../../assets/grey_check.svg"
import { useNavigate } from "react-router-dom";
import { BuildNavigationStepDictionary } from "./NavigationStepDictionary";

export type AppStatusTrackerProps = {
    size: AppStatusTrackerSize,
    stepsComplete: NavigationSteps[],
    verbose: boolean
}

export default function AppStatusTracker(props: AppStatusTrackerProps) {
    const ff = useContext(FeatureFlagContext);
    const navigationStepDictionary = BuildNavigationStepDictionary(ff.featureFlag.featureFlag_HcpApplication_SkillsChecklist_IsEnabled, ff?.featureFlag?.featureFlag_Terms_First);
    const navigate = useNavigate();

    const [currentStatus, setCurrentStatus] = useState<string>("Profile");

    const [isProfileCompleted, setIsProfileCompleted] = useState<boolean>(false);
    const [isSkillsCompleted, setIsSkillsCompleted] = useState<boolean>(ff.featureFlag.featureFlag_HcpApplication_SkillsChecklist_IsEnabled ? false : true);
    const profileSteps = new Map<NavigationSteps, string>();
    const submitSteps = new Map<NavigationSteps, string>();

    if(ff.featureFlag.featureFlag_Terms_First)
        profileSteps.set(NavigationSteps.Application_Documents, "Application Documents");

    profileSteps.set(NavigationSteps.Background, "Background Questions");
    profileSteps.set(NavigationSteps.Address, "Address");
    profileSteps.set(NavigationSteps.License_Info, "License Information");
    profileSteps.set(NavigationSteps.Education, "Education");
    profileSteps.set(NavigationSteps.Employment, "Employment");
    profileSteps.set(NavigationSteps.References, "References");
    profileSteps.set(NavigationSteps.Certifications, "Certifications");
    profileSteps.set(NavigationSteps.Identification, "Identification");

    submitSteps.set(NavigationSteps.TermsAndConditions, "Terms and Conditions")

    useEffect(() => {
        if(props.stepsComplete.includes(NavigationSteps.Identification))
        {
            setIsProfileCompleted(true);
            const currentStatus = props.stepsComplete.includes(NavigationSteps.Skills_Checklist_Summary) ? "Submit" : "Skills"
            setCurrentStatus(currentStatus)  
        }
        
    }, [props.stepsComplete])

    const getMobileClassName = (columnName: string): string => {
        if(columnName === "SkillsChecklist" || columnName === "Submission") { 
            return "hide-status-column"
        } else {
            return "show-status-column"
        }
    }

    const routeToStep = (navigationStep: NavigationSteps) => {
        navigate(navigationStepDictionary[navigationStep].path)
    }

    const GetHeaderItem = (navigationStep: NavigationSteps) => {

        if (props.stepsComplete?.includes(navigationStep)) {
            return (
                <div className="completed-item-container clickable">
                    <div>
                        <img className="header-icon" src={checkmark} alt=""></img>
                    </div>
                    <div className="navigation-header-item completed-item" onClick={() => routeToStep(navigationStep)}>{profileSteps.get(navigationStep)}</div>
                </div>
            );
        }
        if (props.stepsComplete?.includes(navigationStepDictionary[navigationStep].previous as NavigationSteps)) {
            return (
                <div className="completed-item-container clickable">
                        <div>
                            <img className="header-icon" src={greycheckmark} alt=""></img>
                        </div>
                        <div className="navigation-header-item" onClick={() => routeToStep(navigationStep)}>{profileSteps.get(navigationStep)}</div>
                    </div>
            ) 
        }
        if (!ff.featureFlag.featureFlag_Terms_First && navigationStep === NavigationSteps.Background && props.stepsComplete?.length === 1 && props.stepsComplete[0] === NavigationSteps.Started) {
            return (
                <div className="completed-item-container clickable">
                        <div>
                            <img className="header-icon" src={greycheckmark} alt=""></img>
                        </div>
                        <div className="navigation-header-item" onClick={() => routeToStep(NavigationSteps.Background)}>{profileSteps.get(NavigationSteps.Background)}</div>
                    </div>
            ) 
        }
        if (ff.featureFlag.featureFlag_Terms_First && navigationStep === NavigationSteps.Application_Documents && props.stepsComplete?.length === 1 && props.stepsComplete[0] === NavigationSteps.Started) {
            return (
                <div className="completed-item-container clickable">
                        <div>
                            <img className="header-icon" src={greycheckmark} alt=""></img>
                        </div>
                        <div className="navigation-header-item" onClick={() => routeToStep(NavigationSteps.Application_Documents)}>{profileSteps.get(NavigationSteps.Application_Documents)}</div>
                    </div>
            ) 
        }
        return (
            <div className="completed-item-container">
                    <div>
                        <img className="header-icon" src={greycheckmark} alt=""></img>
                    </div>
                    <div className="navigation-header-item">{profileSteps.get(navigationStep)}</div>
                </div>
        ) 
    };

    return (
        <div id="AppStatusContainer" className={props.size === AppStatusTrackerSize.Small ? "component-width-big" : "component-width"}>
            <div className={props.size === AppStatusTrackerSize.Small ? "app-status-container" : "app-status-container-big"}>
            <div>
                <div className="app-status-row">
                    <div className={"app-status-column"} data-testid="profile-dot">
                        <div className="app-status-left-part"></div>  
                        <div className={props.size === AppStatusTrackerSize.Small ? "app-status-div-dot" : "app-status-div-dot-big"}>
                            <img className="dot-image" src={FilledDot} alt="profile-dot"></img>
                        </div>
                        <div className="app-status-right-part">
                            <div data-testid="profile-right-line" className="line-filled"/>
                        </div>
                    </div>

                    {ff.featureFlag.featureFlag_HcpApplication_SkillsChecklist_IsEnabled && 
                    <div data-testid="skills-checklist-dot" className={"app-status-column"}>
                        <div className="app-status-left-part">
                            <div data-testid="skills-left-line" className="line-filled"/>
                        </div>
                        <div className={props.size === AppStatusTrackerSize.Small ? "app-status-div-dot" : "app-status-div-dot-big"}>
                            <img className="dot-image" src={isProfileCompleted ? FilledDot : EmptyDot} alt="skills-checklist-dot"></img>
                        </div>
                        <div className="app-status-right-part">
                            <div data-testid="skills-right-line" className={isProfileCompleted? "line-filled" : "line"}/>
                        </div>
                    </div>
                    }
                    <div className="app-status-column" data-testid="submit-dot">
                        <div className="app-status-left-part">
                            <div data-testid="submit-left-line"  className={isProfileCompleted ? "line-filled" : "line"}/>
                        </div>
                        <div className={props.size === AppStatusTrackerSize.Small ? "app-status-div-dot" : "app-status-div-dot-big"}>
                            <img className="dot-image" src={isSkillsCompleted && isProfileCompleted ? FilledDot : EmptyDot} alt="submit-dot"></img>
                        </div>
                        <div className="app-status-right-part">
                        </div>                    
                    </div>
                </div>

                <div className="app-status-row">
                    <div className="app-status-column center-text">
                            <div 
                                className=
                                    {
                                        (currentStatus === "Profile" ? "current-status-text" : "app-status-text") + 
                                        (props.size === AppStatusTrackerSize.Large ? " big-status-text" : "")
                                    }>
                                Profile
                            </div>
                    </div>

                    {ff.featureFlag.featureFlag_HcpApplication_SkillsChecklist_IsEnabled && 
                    <div className="app-status-column center-text">
                        <div className=
                            {
                                (currentStatus === "Skills" ? "current-status-text" : "app-status-text") + 
                                (props.size === AppStatusTrackerSize.Large ? " big-status-text" : "") 

                            }>
                        {props.verbose ? "Skills Checklist" : "Skills"}
                        </div>
                    </div>
                    }
                    <div className="app-status-column center-text">
                        <div className=
                            {
                                (currentStatus === "Submit" ? "current-status-text" : "app-status-text") + 
                                (props.size === AppStatusTrackerSize.Large ? " big-status-text" : "") 

                            }>
                        {props.verbose ? "Submission" : "Submit"}
                        </div>
                    </div>  
                </div>
            </div>
            {props.size === AppStatusTrackerSize.Large && 
                <div className="app-status-row">
                <div className={"app-status-column " + getMobileClassName('Profile')}>
                    <div className="description-text tracker-items">
                        <div>
                            {ff.featureFlag.featureFlag_Terms_First && GetHeaderItem(NavigationSteps.Application_Documents)}
                            {GetHeaderItem(NavigationSteps.Background)}
                            {GetHeaderItem(NavigationSteps.Address)}
                            {GetHeaderItem(NavigationSteps.License_Info)}
                            {GetHeaderItem(NavigationSteps.Education)}
                            {GetHeaderItem(NavigationSteps.Employment)}
                            {GetHeaderItem(NavigationSteps.References)}
                            {GetHeaderItem(NavigationSteps.Certifications)}
                            {GetHeaderItem(NavigationSteps.Identification)}
                        </div>
                    </div>
                </div>

                {ff.featureFlag.featureFlag_HcpApplication_SkillsChecklist_IsEnabled && 
                <div data-testid="skills-checklist-description" className={"app-status-column " + getMobileClassName('SkillsChecklist')}>
                    <div className="description-text">Based on your work history, we will ask you to complete a skills checklist for the specialties you qualify for. Facilities require this checklist as a part of your application.</div>
                </div>
                }
                
                <div className={"app-status-column " + getMobileClassName('Submission')}>
                    <div className="description-text">Review and submit your application to TRS. Once this step is completed and we have verified your information, you will be eligible for submission to facilities.</div>
                </div>
            </div>
            }
            </div>
        </div>
    );

}
