import { useContext } from "react";
import { NavigationSteps } from "../../tp-core-types/NavigationSteps";
import { NavigationStepInfo } from "./NavigationStepInfo";

const NavigationStepDictionary: { [key in NavigationSteps]: NavigationStepInfo } = {
    [NavigationSteps.Started]: {
        displayName: "",
        path: "",
        previous: null,
        next: NavigationSteps.Background,
    },
    
    [NavigationSteps.TermsAndConditions]: {
        displayName: "Terms and Conditions",
        path: "/terms-and-conditions",
        previous: null,
        next: null,
    },
    [NavigationSteps.Background]: {
        displayName: "Background",
        path: "/background-questions",
        previous: null,
        next: NavigationSteps.Address,
    },
    [NavigationSteps.Address]: {
        displayName: "Address",
        path: "/permanent-address",
        previous: NavigationSteps.Background,
        next: NavigationSteps.License_Info,
    },
    [NavigationSteps.License_Info]: {
        displayName: "License Info",
        path: "/license-info",
        previous: NavigationSteps.Address,
        next: NavigationSteps.Education,
    },
    [NavigationSteps.Education]: {
        displayName: "Education",
        path: "/education-info",
        previous: NavigationSteps.License_Info,
        next: NavigationSteps.Employment,
    },
    [NavigationSteps.Employment]: {
        displayName: "Employment",
        path: "/employment-history",
        previous: NavigationSteps.Education,
        next: NavigationSteps.References,
    },
    [NavigationSteps.References]: {
        displayName: "References",
        path: "/references",
        previous: NavigationSteps.Employment,
        next: NavigationSteps.Certifications,
    },
    [NavigationSteps.Certifications]: {
        displayName: "Certifications",
        path: "/certifications",
        previous: NavigationSteps.References,
        next: NavigationSteps.Identification,
    },
    [NavigationSteps.Identification]: {
        displayName: "Identification",
        path: "/identification-info",
        previous: NavigationSteps.Certifications,
        next: NavigationSteps.TermsAndConditions,
    },
    [NavigationSteps.Skills_Checklist]: {
        displayName: "Skills Checklist",
        path: "/skills-checklist",
        previous: null,
        next: null,
    },
    [NavigationSteps.Skills_Checklist_Summary]: {
        displayName: "Skills Checklist Summary",
        path: "/skills-checklist-summary",
        previous: null,
        next: null,
    },
    [NavigationSteps.EEOSelfIdentification]: {
        displayName: "Equal Employment Opportunity Self-Identification",
        path: "/eeo",
        previous: null,
        next: null,
    },
    [NavigationSteps.Application_Documents]: {
        displayName: "Application Documents",
        path: "/application-documents",
        previous: NavigationSteps.Identification,
        next: null,
    }
};

export const BuildNavigationStepDictionary = (isSkillsChecklistFeatureFlagEnabled: boolean, termsFirstFlagEnabled: boolean) => {
    // if(isSkillsChecklistFeatureFlagEnabled)
    // {
    //     NavigationStepDictionary.Identification.next = NavigationSteps.Skills_Checklist;
    //     NavigationStepDictionary[NavigationSteps.Skills_Checklist].previous = NavigationSteps.Identification;
    //     NavigationStepDictionary[NavigationSteps.Skills_Checklist].next = NavigationSteps.Skills_Checklist_Summary;

    //     NavigationStepDictionary[NavigationSteps.Skills_Checklist_Summary].previous = NavigationSteps.Skills_Checklist;
    //     NavigationStepDictionary[NavigationSteps.Skills_Checklist_Summary].next = NavigationSteps.TermsAndConditions;
    // }
    if(termsFirstFlagEnabled){
        NavigationStepDictionary[NavigationSteps.Started].next = NavigationSteps.Application_Documents;
        NavigationStepDictionary[NavigationSteps.Application_Documents].next = NavigationSteps.Background;
        //NavigationStepDictionary[NavigationSteps.Identification].next = NavigationSteps.TermsAndConditions;
    }

    return NavigationStepDictionary;
};
