import { createContext } from "react";

export type FeatureFlagsModel = {
    featureFlag : {
        featureFlag_HcpApplication_SkillsChecklist_IsEnabled: boolean,
        featureFlag_DashboardAssignments_IsEnabled: boolean,
        featureFlag_DocumentsAndSignatures_IsEnabled: boolean
        featureFlag_ReferAFriend_IsEnabled: boolean,
        featureFlag_UpdateBackgroundQuestions_IsEnabled: boolean,
        featureFlag_CredentialsDropbox_IsEnabled: boolean,
        featureFlag_Timesheets_IsEnabled: boolean,
        featureFlag_HubIsSunset: boolean,
        featureFlag_Rto_IsEnabled: boolean
        featureFlag_Terms_First: boolean
    }
}

const defaultValue: FeatureFlagsModel = {
    featureFlag: {
        featureFlag_HcpApplication_SkillsChecklist_IsEnabled: false,
        featureFlag_DashboardAssignments_IsEnabled: false,
        featureFlag_DocumentsAndSignatures_IsEnabled: false,
        featureFlag_ReferAFriend_IsEnabled: false,
        featureFlag_CredentialsDropbox_IsEnabled: false,
        featureFlag_UpdateBackgroundQuestions_IsEnabled: false,
        featureFlag_Timesheets_IsEnabled: false,
        featureFlag_HubIsSunset: false,
        featureFlag_Rto_IsEnabled: false,
        featureFlag_Terms_First: false
    }
}

const FeatureFlagContext = createContext(defaultValue);

export default FeatureFlagContext;